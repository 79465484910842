<template>
  <div class="app">
    <div class="top">
      <el-row type="flex" class="row-bg" justify="space-between">
        <!-- <el-col :span="6"><div class="grid-content bg-purple"><p class="word">收入</p><div class="iacount"><span class="icon">￥</span><span class="acount">9240.00</span></div></div></el-col> -->
        <el-col :span="10"
          ><div class="grid-content bg-purple-light">
            <p class="word">余额</p>
            <div class="iacount" v-if="novisible">
              <span class="icon">￥</span>
              <span class="acount">{{ balance }}</span>
              <img
                src="../../assets/home/quanquan.png"
                alt=""
                @click="anovisible"
              />
            </div>
            <div class="iacount" v-else>
              <span class="icon"></span>
              <span class="acount">*******</span>
              <img
                src="../../assets/home/visible.png"
                alt=""
                @click="bnovisible"
              />
            </div>
          </div>
        </el-col>
        <el-col :span="1"
          ><div class="grid-content bg-purple">
            <el-button type="danger" @click="tixain">提现</el-button>
          </div></el-col
        >
        <el-dialog
          :before-close="unwithdraw"
          :visible.sync="withdrawVisible"
          width="30%"
        >
          <div class="amount">
            <el-input
              v-model="amount"
              placeholder="请输入提现金额"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <p>元</p>
          </div>

          <div class="footeracount">
            <el-button type="primary" @click="unwithdraw">取 消</el-button>
            <el-button type="danger" @click="withdraw">确 定</el-button>
          </div>
        </el-dialog>
      </el-row>
      <Iframe
        :iframeDialog="iframeDialog"
        v-if="hackReset"
        :resulturl="resulturl"
        @del="del"
      
      ></Iframe>
      <div class="block">
        <el-date-picker
          v-model="queryInfo.startDate"
          type="date"
          @change="changestatetime"
          placeholder="选择开始日期"
        >
        </el-date-picker>
        <el-date-picker
          v-model="queryInfo.endDate"
          type="date"
          @change="changeendtime"
          placeholder="选择结束日期"
        >
        </el-date-picker>
      </div>
      <!-- 记录 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="收入记录" name="0">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="receiptNo" label="订单编号" width="180">
            </el-table-column>
            <el-table-column prop="tradeType" label="订单类型" width="110">
            </el-table-column>
            <el-table-column prop="tradeDate" label="订单时间">
            </el-table-column>
            <el-table-column prop="amount" label="收入(元)" width="90">
            </el-table-column>
            <el-table-column prop="balanceAmount" label="余额(元)" width="90">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="支出记录" name="1">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="receiptNo" label="订单编号" width="180">
            </el-table-column>
            <el-table-column prop="tradeType" label="订单类型" width="110">
            </el-table-column>
            <el-table-column prop="tradeDate" label="订单时间">
            </el-table-column>
            <el-table-column prop="amount" label="收入(元)" width="90">
            </el-table-column>
            <el-table-column prop="balanceAmount" label="余额(元)" width="90">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="全部记录" name="2">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="receiptNo" label="订单编号" width="180">
            </el-table-column>
            <el-table-column prop="tradeType" label="订单类型" width="110">
            </el-table-column>
            <el-table-column prop="tradeDate" label="订单时间">
            </el-table-column>
            <el-table-column prop="amount" label="收入(元)" width="90">
            </el-table-column>
            <el-table-column prop="balanceAmount" label="余额(元)" width="90">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- 分页 -->
      </el-tabs>
<!-- 
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="  prev, pager, next"
        :total="total"
      >
      </el-pagination> -->
      <Pagination   class="el-pagination " v-if="tableData.length>0" :num="total" ref="chanld" :limit="queryInfo.pageSize" :totalPageCount="totalPageCount"></Pagination>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import incomeApi from "../../api/incomeApi";
import Pagination from "@/components/prePage/pagination.vue";
import Iframe from "../center/iframe.vue";
import { log } from "../../utils/EMedia_x1v1_3.4.1";
export default {
  data() {
    return {
      //提现金额
      hackReset:true,
      amount: "",
      iframeDialog: false,
      withdrawVisible: false,
      novisible: false,
      balance: "",
      total: 0,
       totalPageCount:0,
      query: "",
      resulturl: "",
      queryInfo: {
        currentPage: 1,
        detailAmountType: 0,
        endDate: "",
        pageSize: 5,
        startDate: "",
      },
   
      activeName: 0,
      tableData: [],
    };
  },
 mounted(){
 this.queryInfo.startDate = this.GetTime();
    this.queryInfo.endDate = this.getTimenow();
    this.getBalDetail();
    
 },

  components: {
    Iframe,
    Pagination
  },
  methods: {
     change(even){
 this.queryInfo.currentPage = even
 this.getBalDetail();
    },
    del(bool) {
      this.iframeDialog = bool;
      this.hackReset = false
this.$nextTick(() => {
this.hackReset = true
})
    },
    unwithdraw() {
      this.withdrawVisible = false;
      this.amount = "";
    },
    // 开始时间
    changestatetime(e) {
      
      this.queryInfo.startDate =this.$moment(
             e
            ).format("yyyy-MM-DD");
      this.getBalDetail();
    },
    // 结束时间
    changeendtime(e) {
      this.queryInfo.endDate =this.$moment(
             e
            ).format("yyyy-MM-DD");
      this.getBalDetail();
    },
    //  提现
    withdraw() {
      if (this.amount) {
        incomeApi.getCompanyUserList({ amount: this.amount }, (res) => {
          if (res.data.code == 200) {
            this.$store.commit("intiframestate", "withdraw");
            this.resulturl =
              res.data.result +
              "?Address=http://" +
              window.location.host +
              "/#/iframeBack";
            this.withdrawVisible = false;
         
         
            this.iframeDialog = true;
          
            this.amount = "";
          } else {
            Message.error(res.data.message);
          }
        });
      } else {
        Message.error("请输入正确的金额");
      }
    },
    // 原密码不可见
    anovisible() {
      this.getBalanceNew();
    },
    bnovisible() {
      this.getBalanceNew();
      this.novisible = true;
    },
    // 结束时间
    getTimenow: function () {
      var date = new Date(),
        year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();
      month >= 1 && month <= 9 ? (month = "0" + month) : "";
      day >= 0 && day <= 9 ? (day = "0" + day) : "";
      var timer = year + "-" + month + "-" + day;
      return timer;
    },
    //  开始时间
    GetTime() {
      var date = new Date();
      var base = Date.parse(date); // 转换为时间戳
      var year = date.getFullYear(); //获取当前年份
      var mon = date.getMonth() + 1; //获取当前月份
      var day = date.getDate(); //获取当前日
      var oneDay = 24 * 3600 * 1000;
      // this.$data.daytime = daytime; // 今日时间赋值给变量
      var daytimeArr = "";
      for (var i = 1; i < 7; i++) {
        //前七天的时间
        var now = new Date((base -= oneDay));
        var myear = now.getFullYear();
        var month = now.getMonth() + 1;
        var mday = now.getDate();
        //  var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        //  var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        //  var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        if (i == 6) {
          daytimeArr = [
            myear,
            month >= 10 ? month : "0" + month,
            mday >= 10 ? mday : "0" + mday,
          ].join("-");
        }
      }
      return daytimeArr;
    },
    // 获取当前收入明细
    getBalDetail() {
      var newtableData = [];
      incomeApi.getBalDetail(this.queryInfo, (res) => {
       
        if (res.data.code == 200) {
        
          if (res.data.result.data.length > 0) {
            res.data.result.data.forEach((element) => {
               newtableData.push(element);
            });
            
          }
          this.tableData = newtableData;
            this.total = res.data.result.totalCount;
            this.totalPageCount = res.data.result.totalPageCount;
        }
      });
    },
    //获取当前用户余额
    getBalanceNew() {
      incomeApi.getBalanceNew("", (res) => {
        if (res.data.code == 200) {
          this.balance = res.data.result;
        } else {
          Message.error(res.data.message);
        }
      });
    },
    //tab 点击
    handleClick(tab, event) {
      this.queryInfo.detailAmountType = Number(tab.name);
        this.queryInfo.currentPage = 1
      this.getBalDetail();
     
    },
    //监听pageSize改变事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getBalDetail();
    },
    //监听currentPage改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getBalDetail();
    },
    // 提现
    tixain() {
      this.withdrawVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.footeracount {
  width: 60%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}
.amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin: auto;
  margin-bottom: 50px;

  p {
    font-size: 14px;
    padding-left: 10px;
  }
}
.block {
  // border: 1px solid red;
  margin-top: 30px;
}
.el-date-editor {
  margin-right: 10px;
}
.app {
  width: 680px;
  //  border: 1px solid red;
  height: 600px;
  // margin-left: 100px;
  // min-width: 1340px;

  .top {
    width: 100%;
    height: 100px;
    // border: 1px solid red;
    border-bottom: 1px solid #ccc;
  }
  .el-row {
    width: 90%;
    // border: 1px solid red;
    height: 90px;
    align-items: center;
    .word {
      width: 36px;
      height: 25px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      line-height: 25px;
    }
    .icon {
      font-size: 16px;
      //  margin-top: 10px;
      //  border: 1px solid red;
      width: 20px;
      height: 20px;
      position: relative;
      top: -10px;
    }
    .iacount {
      margin-left: -20px;

      img {
        width: 20px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
    .acount {
      width: 183px;
      height: 56px;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      line-height: 56px;
      vertical-align: middle;
      margin-right: 20px;
    }
  }
  .el-tabs {
    // border: 1px solid red;
    width: 100%;
    margin-top: 10px;
    height: 420px;
    .el-table {
      //  border: 1px solid red;
      // height: 100%;
      margin-bottom: 20px;
    }
  }
  ::v-deep .has-gutter .is-leaf {
    background-color: #eaedf4;
    border: 1px solid white;
  }
}
.el-pagination {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 20px;
}
</style>