
import baseApi from './baseApi.js';
const incomeApi = {
	// 获取当前用户收支明细
	getBalDetail(params,callback){
		baseApi.get('center/paymentconfig/getBalDetail',params,callback)
	},
	// 获取当前用户余额
	getBalanceNew(params,callback){
		baseApi.get('center/paymentconfig/getBalanceNew'+params,params,callback)
	},
	// 当前用户提现
	getCompanyUserList(params,callback){
		baseApi.post('center/paymentconfig/companyWithdrawPC?amount='+params.amount,params,callback)
	},

	
	
}
//抛出api 
export default incomeApi